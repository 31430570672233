import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import AboutUsContent from '../components/AboutUs/AboutUsContent'
import OurHistory from '../components/AboutUs/OurHistory'
import TagsForAll from '../components/Common/TagsForAll'
import { Helmet } from 'react-helmet'

const AboutUs = () => {
    return (
        <Layout>
        <TagsForAll pageTitle="About Us" />
        <Helmet>
            <meta name='robots' content='index, follow' />
            <link rel="canonical" href="https://zaavia.net/about-us" />
        </Helmet>
            <Navbar />
            <PageBanner
                pageTitle="About Us" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="About Us" 
            />
            <AboutUsContent />
            <OurHistory />
            <Footer />
        </Layout>
    );
}

export default AboutUs;